import style from './style.module.less';

import FormSelect from '../../Form/Select';
import Option from '../../Form/Select/Option';
import FormButton from '../../Form/Button';
import { getGlobalField, setGlobalField } from '../../../helpers';

const StepSelect = (props) => {

  const {
    items = [
      {value: 'consultancy', title: 'Консультация'},
      {value: 'management', title: 'Управление недвижимостью'},
      {value: 'sell', title: 'Заняться продажей объекта'},
      {value: 'tenant', title: 'Помочь найти арендатора'},
      {value: 'rent', title: 'Помочь найти недвижимость в аренду'},
      {value: 'inspection', title: 'Сделать подборку объектов на просмотр'},
      {value: 'buy', title: 'Помочь купить недвижимость'}
    ],
    onSubmit = (e) => {},
    onChange = (e) => {}
  } = props;

  const handleClickButton = (e) => {

    const type = getGlobalField('searchTask_task');
    
    if( typeof type === 'undefined' ) {
      setGlobalField('searchTask_task', items[0]);
    }

    if( typeof onSubmit === 'function' ) {
      return onSubmit(e);
    }
  }

  const handleChangeSelect = (e) => {
    const value = JSON.parse(e.target.value);
    setGlobalField('searchTask_task', value);

    if( typeof onChange === 'function' ) {
      return onChange(e);
    }
  }

  // Render
  return (
    <>
      <h2>Выберите какую задачу вы хотите решить</h2>

      <div className={style.form}>
        <FormSelect name="task" className={style.select__field} onChange={handleChangeSelect}>
          {items.map((item) => (
            <Option value={JSON.stringify(item)} key={JSON.stringify(item)}>
                {item.title}
            </Option>
          ))}
        </FormSelect>

        <FormButton onClick={handleClickButton} className={style.btn__form} isOuter={true}>Отправить</FormButton>
      </div>
    </>
  );
}

export default StepSelect;