import style from './style.module.less';

/**
 * Item of popular entities
 * - child component
 * 
 * @param {object} props 
 * @returns 
 */
const PopularEntitieItem = (props) => {

    const {
        data
    } = props;

    // Render
    return (
        <a href={data?.www_link} target="_blank" rel="noreferrer" className={style.item} style={{backgroundImage: `url('${data?.photos[0]?.medium}')`}}>
            <div className={style.container}>
                <div className={style.label}>
                    {data?.city?.name}
                </div>
            </div>
        </a>
    );
}

export default PopularEntitieItem;