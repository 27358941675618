import { useState, useEffect } from 'react';
import style from './style.module.less';
import Section from '../Section';
import { scrollToDOM } from '../../helpers';

/**
 * Pagination component
 * 
 * @param {object} props 
 * @returns 
 */
const Pagination = (props) => {

    const {
        className = '',
        perPage = 20,
        items = 180,
        countItems = 5,
        currentPage: propsCurrentPage = 1,
        onChangePage = (page, item, e) => { }
    } = props;

    const [currentPage, setCurrentPage] = useState(propsCurrentPage);

    const countPages = Math.ceil(items / perPage);
    const countPagesInSides = Math.floor(countItems / 2);

    let pagination = [];

    let isShowLastPage = false;
    let isShowFirstPage = false;

    let isLeftArrow = false;
    let isRightArrow = false;

    // Counting pagination start item id
    let startPagination = 1;
    let endPagination = countItems;

    // On middle pagination current page
    if (currentPage - countPagesInSides >= 1) {
        startPagination = currentPage - countPagesInSides;
        endPagination = currentPage + countPagesInSides;
    }

    // On end current page
    if (currentPage + countPagesInSides >= countPages) {
        startPagination = countPages - countItems + 1;
        endPagination = countPages;
    }

    // On low count pages
    if (countPages <= countItems) {
        startPagination = 1;
        endPagination = countPages;
    }

    if (countPages <= countItems) {
        endPagination = countPages;
    }

    // Constuct pagination array
    for (let pageIndex = startPagination; pageIndex <= endPagination; pageIndex++) {

        let item = {
            label: pageIndex,
            link: `/agents/${pageIndex}`,
            index: pageIndex,
            classList: [style.pagination_item]
        };

        if (pageIndex === currentPage) {
            item.classList.push(style.active);
        }

        item.classList = item.classList.join(' ');
        pagination.push(item);

    }

    // Is show first page in pagination
    if (countPages > countItems && currentPage > countItems - 2) {
        isShowFirstPage = true;
    }

    // Is show last page in pagination
    if (countPages > countItems && currentPage <= (countPages - countItems + 2)) {
        isShowLastPage = true;
    }

    // Is show left arrow?
    if (currentPage >= 2) {
        isLeftArrow = true;
    }

    // Is show right arrow?
    if (currentPage < countPages) {
        isRightArrow = true;
    }

    useEffect(() => {
        onChangePage(currentPage);
    }, [currentPage, onChangePage]);

    /**
     * For handle click on page item
     * 
     * @param {DOMEvent} e 
     */
     const handleClickItem = (e) => {
        const item = e.target;
        const selectedPage = parseInt(item.dataset.page);
        setCurrentPage(selectedPage);
        scrollToDOM('[data-scroll="filter-menu"]');
    }

    if (countPages <= 1) {
        return (
            <></>
        );
    }

    // Render
    return (
        <Section>
            <div className={[style.pagination, className].join(' ')}>

                {isLeftArrow && (
                    <div
                        className={[style.arrow, style.arrow_left].join(' ')}
                        data-page={currentPage - 1}
                        onClick={handleClickItem}
                    ></div>
                )}

                {isShowFirstPage && (
                    <>
                        <div
                            className={style.pagination_item}
                            data-page={1}
                            onClick={handleClickItem}
                        >
                            1
                        </div>
                        <div className={style.dots}>...</div>
                    </>
                )}

                {pagination.map((item, index) => {
                    return (
                        <div
                            key={`page_${item.label}`}
                            className={item.classList}
                            onClick={handleClickItem}
                            data-page={item.index}
                        >
                            {item.label}
                        </div>
                    );
                })}

                {isShowLastPage && (
                    <>
                        <div className={style.dots}>...</div>
                        <div
                            className={style.pagination_item}
                            data-page={countPages}
                            onClick={handleClickItem}
                        >{countPages}</div>
                    </>
                )}

                {isRightArrow && (
                    <div
                        className={[style.arrow, style.arrow_right].join(' ')}
                        data-page={currentPage + 1}
                        onClick={handleClickItem}
                    ></div>
                )}

            </div>
        </Section>
    );
}

export default Pagination;