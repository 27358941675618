import { useState } from "react";
import style from './style.module.less';

import Section from '../Section';
import Select from '../Form/Select';
import { getGlobalField, setGlobalField, subscribeField } from '../../helpers';

/**
 * Filter for agents list
 * 
 * @param {object} props 
 * @returns 
 */
const AgentsFilter = (props) => {
  const {
    className = '',
    // menu = [],
    // onChangeMenu = (e) => { },
    onChnageLocation = (e) => { },
    onChnageName = (e) => { },
    onChnageService = (e) => { },
    onChnagePrice = (e) => { },
    onClickSearch = (e) => { },
    onChangeFilter = (filterData, e) => { }
  } = props;

  // const [activeMenuItem, setActiveMenuItem] = useState('list');
  const [locationValue, setLocationValue] = useState('');
  const [agentNameValue, setAgentNameValue] = useState('');
  const [serviceValue, setServiceValue] = useState('');
  const [priceValue, setPriceValue] = useState('');

  // Subscribe on update filter field
  subscribeField('agentsFilterData', (filterData) => {
    onChangeFilter(filterData);
  });

  /**
   * For change filter object
   * 
   * @param {string} field 
   * @param {string} value 
   */
  const updateFilterData = (field, value) => {
    let filterData = getGlobalField('agentsFilterData');

    if( !filterData || typeof filterData !== 'object' ) {
      filterData = {
        name: agentNameValue,
        location: locationValue,
        service: serviceValue,
        price: priceValue
      }
    }

    filterData[field] = value;

    setGlobalField('agentsFilterData', filterData);
  }

  // Select menu item
  // const handleClickMenu = (e) => {
  //   setActiveMenuItem(e.target.dataset.tag);

  //   if (typeof onChangeMenu === 'function') {
  //     onChangeMenu(e);
  //   }
  // }

  // Change Location
  const handleChangeLocation = (e) => {
    setLocationValue(e.target.value);
    updateFilterData('location', e.target.value);

    if (typeof onChnageLocation === 'function') {
      return onChnageLocation(e);
    }
  }

  // Change Name
  const handleChangeName = (e) => {
    setAgentNameValue(e.target.value);
    updateFilterData('name', e.target.value);

    if (typeof onChnageLocation === 'function') {
      return onChnageName(e);
    }
  }

  // Change Service
  const handleChangeService = (e) => {
    setServiceValue(e.target.value);
    updateFilterData('service', e.target.value);

    if (typeof onChnageService === 'function') {
      return onChnageService(e);
    }
  }

  // Change Price
  const handleChangePrice = (e) => {
    setPriceValue(e.target.value);
    updateFilterData('price', e.target.value);

    if (typeof onChnagePrice === 'function') {
      return onChnagePrice(e);
    }
  }

  // On search
  const handleClickSearch = (e) => {
    if (typeof onClickSearch === 'function') {
      return onClickSearch(e);
    }
  }

  // Render
  return (
    <Section className={[style.section, className].join(' ')}>

      <div className={style.filter}>

        <div className={style.field}>
          <input type="text" name="address" onChange={handleChangeLocation} className={style.input} value={locationValue} placeholder="Локация" />
          <button className={style.search} onClick={handleClickSearch}></button>
        </div>

        <div className={style.field}>
          <input type="text" name="name" onChange={handleChangeName} className={style.input} value={agentNameValue} placeholder="Имя агента" />
          <button className={style.search} onClick={handleClickSearch}></button>
        </div>

        <Select
          name="service"
          onChange={handleChangeService}
          className={style.select}
          arrowClassName={style.select_arrow}
          wrapperClassName={style.field}
          value={serviceValue}
        >
          <option selected>Какой сервис нужен</option>
          <option value="buy">Покупка недвижимости</option>
          <option value="sell">Продажа недвижимости</option>
          <option value="inspection">Осмотр недвижимости</option>
          <option value="rent">Аренда недвижимости</option>
          <option value="tenant">Сдача в аренду недвижимости</option>
          <option value="management">Управление недвижимостью</option>
          <option value="consultancy">Консультирование</option>
        </Select>

        <Select
          name="price"
          onChange={handleChangePrice}
          className={style.select}
          arrowClassName={style.select_arrow}
          wrapperClassName={style.field}
          value={priceValue}
        >
          <option selected>Бюджет</option>
          <option value="100">50 000 - 150 000</option>
          <option value="200">150 000 - 300 000</option>
          <option value="300">300 000 - 500 000</option>
          <option value="400">500 000 - 1 000 000</option>

          <option value="500">1 000 000 - 1 500 000</option>
          <option value="600">1 500 000 - 2 500 000</option>
          <option value="700">2 500 000 - 5 000 000</option>

          <option value="800">5 млн. - 7 млн.</option>
          <option value="900">7 млн. - 10 млн.</option>

          <option value="1000">10 млн. - 15 млн.</option>
          <option value="2000">15 млн. - 20 млн.</option>
          <option value="3000">25 млн. - 30 млн.</option>
          <option value="4000">30 млн. - 35 млн.</option>
          <option value="5000">35 млн. - 45 млн.</option>
          <option value="6000">45 млн. - 55 млн.</option>
          <option value="7000">55 млн. - 65 млн.</option>
          <option value="8000">75 млн. - 85 млн.</option>
          <option value="9000">85 млн. - 100 млн.</option>

          <option value="10000">от 100 млн.</option>
        </Select>

      </div>

    </Section>
  );
}

export default AgentsFilter;